.cart__container {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.763);
    max-width: 1440px;
    margin: 0 auto;

    .cart__Header {
        margin: 20px auto;

        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 10px;
        box-shadow: -2px 14px 11px -5px #0000007a;

        // border-bottom: 1px solid rgba(0, 0, 0, 0.763);

        .device__location {
            flex-basis: 50%;

            input {
                border: none;
                font-size: larger;
                border-bottom: 1px solid rgba(128, 128, 128, 0.466);
            }
        }
    }

    .cartItem_details_contianer {
        display: flex;
        width: 100%;
        justify-content: space-around;

        .cartItem__Container {
            // border: 1px solid rgba(58, 57, 57, 0.776);
            width: 800px;
            box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 48%);
            padding: 15px;

            .hotel__heading {
                display: flex;
                justify-content: space-around;
                align-items: center;

                gap: 10px;

                .hotel_details {
                    flex-basis: 90%;
                    // border-bottom: 1px solid black;

                    p {
                        margin: 0;
                    }
                }

                .hotel_img {
                    flex-basis: 10%;

                    img {
                        width: 100px;
                        height: 100px;
                    }

                }
            }

            .item__container {
                border: 2px solid rgba(255, 0, 0, 0.482);
                display: flex;
                margin-top: 20px;
                border-radius: 5px;

                .item__description {
                    // border: 1px solid blue;
                    max-width: 600px;
                    padding: 20px;
                    flex-basis: 80%;
                    display: flex;
                    flex-direction: column;

                    label {
                        text-decoration: underline;
                        margin: 10px 0px;
                    }

                    img {
                        width: 200px;
                        border-radius: 15px;

                    }

                    .item_desc {
                        font-size: small;
                        color: rgba(63, 36, 12, 0.646);
                    }

                    .item_price {
                        margin-top: 10px;
                        font-weight: bolder;
                    }

                    p {
                        margin: 0;

                    }
                }

                .item__count__total {
                    flex-basis: 25%;
                    position: relative;
                    display: flex;
                    justify-content: end;
                    align-items: center;

                    button {
                        align-self: baseline;
                        margin: 5px;
                        background-color: white;
                        border: none;

                        .delete_icon {
                            width: 25px;
                        }
                    }
                }
            }
        }

        .cartSummary__Container {
            // border: 1px solid blue;
            display: flex;
            flex-direction: column;
            padding: 15px;
            box-shadow: 0px 0px 11px 0px rgb(0 0 0 / 48%);
            height: fit-content;

            p {
                margin: 10px 0px;

                font-weight: 500;
            }

            .order__value p {
                text-align: end;
            }

            .order__details {
                display: flex;
                justify-content: space-between;
                align-items: center;

                .order__name {
                    max-width: 70%;
                }
            }

            button {
                align-self: center;
                margin: 5px;
                background-color: rgb(25, 118, 210);
                border: none;
                color: white;
                text-transform: uppercase;
                min-width: 64px;
                padding: 6px 16px;
                border-radius: 4px;
                box-shadow: rgb(0 0 0 / 20%) 0px 3px 1px -2px, rgb(0 0 0 / 14%) 0px 2px 2px 0px, rgb(0 0 0 / 12%) 0px 1px 5px 0px;
                transition: background-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    box-shadow 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    border-color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms,
                    color 250ms cubic-bezier(0.4, 0, 0.2, 1) 0ms;

                &:active {
                    box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
                }
            }
        }
    }
}