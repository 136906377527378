.login_signup_container {
    z-index: 101;
    background-color: white;
    position: fixed;
    background-color: #fff;
    border: 1px solid #f5f5f6;
    padding: 25px;
    box-shadow: 0 1px 10px rgb(0 0 0 / 8%);
    top: 20%;
    left: 32%;
    min-width: 32%;
    border-radius: 15px;
    ;

    h1 {
        text-align: center;
        text-shadow: #ff2d12;
        padding: 10px;
        // border: 1px solid red;
    }

    .tab {
        width: 100%;
        background-color: #fff;

        button {
            padding: 10px;
            border: none;
            background-color: white;
            margin-bottom: 5px;
            font-size: large;
            text-align: center;
            padding-right: 15px;
        }

        button.active {
            box-shadow: inset 0px -5px 0px 0px #ff2d12;
            font-weight: bold;
            font-size: larger;
        }

    }

    margin: 0 auto;
}