.carousel {
    margin-top: 30px;
    border-radius: 30px;
    overFlow: hidden;
    position: relative;

    // background:black;
    .inner {
        white-space: nowrap;
        transition: transform 0.5s;

    }


    .active__Dots {
        // border: 1px solid red;
        z-index: 5;
        width: 5em;
        bottom: 14px;
        right: 10px;
        padding: 1rem;
        position: absolute;

        img {
            padding: 5px
        }


    }

}