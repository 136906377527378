.button {
    button {
        padding: 4px 10px;
        margin-right: 10px;
        border-radius: 50px;
        border: 1px solid #C1C1C1;
        background: white;


        &:hover {
            scale: 1.2;
            border: 1px solid black;
        }
    }
}

.active {
    button {
        padding: 4px 10px;
        margin-right: 10px;
        background-color: black;
        color: WHITE;
        border-radius: 50px;
        border: 1px solid black;
    }

}