.date__width {
    max-width: 60%;

    .borderBottom {
        border-bottom: 1px solid black !important;
        border-radius: 0;

        &input ::placeholder {
            color: black !important;
        }
    }
}