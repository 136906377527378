.banner__container {
    z-index: 6;
    max-width: 100%;
    position: absolute;
    top: -100px;
    right: 19%;
    left: 19%;

    img {
        max-width: 100%;
    }
}