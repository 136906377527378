.attraction__container {
    background-color: #F8F1ED;
    max-width: 1440px;
    margin: 0 auto;
    margin-top: 74px;

    .attraction__holder {
        padding: 3.75rem;
        position: relative;

        .left__arrow {
            position: absolute;
            bottom: 38%;
            left: 48px;
            z-index: 5;
        }

        .right__arrow {
            position: absolute;
            bottom: 38%;
            right: 38px;
            z-index: 5;
        }
    }
}