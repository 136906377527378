.restaurant__container {
    max-width: 100%;
    margin-top: 20px;

    .restaurant__Header {
        margin: 0 auto;
        max-width: 1280px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .device__location {
            flex-basis: 50%;

            input {
                border: none;
                font-size: larger;
                border-bottom: 1px solid rgba(128, 128, 128, 0.466);
            }
        }
    }

    .restaurant_banner {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        background-color: #171a29;
        // background-color: black;
        height: 240px;
        align-items: center;
        gap: 80px;
        color: white;
        position: sticky;
        z-index: 10;
        top: -1px;

        .resImage {
            flex-basis: 20%;
            max-width: 245px;
            max-height: 165px;
        }

        .restaurant_desc {
            flex-basis: 20%;

            label {
                font-size: larger;
            }

            .restaurant_desc {
                margin: 0;
                font-size: 14px;
                font-weight: light;
                color: #99999c
            }

            .restaurant_address {
                font-size: 14px;
                font-weight: light;
                color: #99999c
            }

            .restaurant_detail {
                display: flex;
                font-size: 14px;
                font-weight: light;
                justify-content: flex-start;
                gap: 10px;
                align-items: center;

                .rating__highlight {
                    color: white;
                }

                .priceSelection {
                    p {
                        margin: 0;
                    }

                    white-space: nowrap;
                }
            }
        }

        .restaurants_offer {
            flex-basis: 30%;
            position: relative;
            border: 1px solid white;
            padding: 2rem;

            .offerHeading {
                padding: 0 5px;
                position: absolute;
                top: -13px;
                background-color: #171a29;
                left: 10px;
            }

        }
    }

    .menu_container {
        display: flex;
        margin: 0 auto;
        max-width: 1200px;
        overflow: hidden;

        .sub_categories {
            // flex-basis: 10%;
            display: flex;
            flex-direction: column;
            margin: 10px;
            cursor: pointer;
            position: sticky;
            top: -30;

            a {
                color: black;
                text-decoration: none;
            }
        }

        .menu_list {
            border-left: 1px solid rgba(0, 0, 0, 0.39);
            flex-basis: 80%;
            overflow-y: auto;

            .sub_categories__Expand {
                margin: 30px;

                label {
                    font-size: 24px;
                    font-weight: bolder;
                    text-decoration: underline;
                }

                .foodItem__container {
                    // border: 1px solid red;
                    padding: 40px 0px;
                    margin: 20px 0;
                    display: flex;
                    gap: 40px;
                    max-width: 900px;
                    border-bottom: 1px solid rgba(0, 0, 0, 0.521);
                    align-content: stretch;
                    justify-content: space-between;


                    .foodItem__desc {
                        flex-basis: 50%;
                        max-width: calc(100% - 144px);

                        .food_label {
                            font-size: 16px;
                            text-decoration: none;
                        }

                        p {
                            margin: 0;
                            margin-top: 5px;
                        }

                        .desc {
                            color: rgba(0, 0, 0, 0.502)
                        }
                    }

                    .img__add {
                        position: relative;

                        .overImage {
                            position: absolute;
                            top: 123px;
                            left: 48px;
                            background-color: white;
                            border: 1px solid green;
                            padding: 5px 15px;
                            border-radius: 5px;
                        }

                        .standAlone {
                            // text-align: center;
                            margin: 1rem 3rem 0 0;
                            background-color: white;
                            border: 1px solid green;
                            padding: 5px 15px;
                            border-radius: 5px;
                        }

                        img {
                            max-width: 150px;
                            height: 120px;
                            margin: 20px 0;
                        }
                    }
                }
            }
        }
    }
}