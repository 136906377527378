.routes__container {
    max-width: 80.19rem;
    margin: 0 auto;
    // border: 1px solid yellow;
    background-color: #F3F3F3;
    display: flex;
    gap: 1.2rem;
    padding: 20px;
    justify-content: center;
    flex-wrap: wrap;

    .route__item {
        // border: 1px solid #F13844;
        max-width: 18.75rem;
        padding: 16px;
        border-radius: 10px;
        background-color: white;

        &.highLight {
            border: 1px solid #FF4B56;
            transform: scale(1.05);
            box-shadow: #F3F3F3;
        }

        .route__Path {
            margin-top: 8px;
            font-size: 13px;
            font-weight: bold;
            display: flex;
            flex-wrap: wrap;
            max-width: 12rem;

            p {
                margin: 0;
            }
        }

        .route_map_container {
            margin-top: 17px;
            min-width: 267px;
        }

        .route__info {
            margin-top: 21px;

            .route__info__details {
                font-size: 13px;
                font-weight: bold;
            }

            .route__iternaries__icon {
                margin-top: 17px;
            }
        }
    }

    .route_map_container {
        max-width: 16.72rem;
        min-height: 15rem;
        contain: content;
    }

    .route_map_container__expaned {
        min-width: 70%;
        min-height: 63%;
        position: absolute;
        background: white;
        box-shadow: 0px -1px 20px 0px #303030;
        top: 22%;
        left: 15%;
        padding: 10px 0px;
        contain: content;
        border-radius: 5px;

        .expandCloseButton {
            display: flex;
            justify-content: space-between;
            font-size: large;
            font-weight: bolder;
            margin-bottom: 5px;

            label {
                padding-left: 5px;
            }

            button {
                border: none;
                background-color: white;

            }
        }

        .mapContainer {
            padding-top: 5px;
        }
    }
}