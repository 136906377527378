.recent_container {
    margin-top: 60px;

    h1 {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        font-size: 24px;
        line-height: 36px;
        margin: 0 0 15px 0;
    }

    .search__tab__container {
        max-width: 90%;
        display: flex;
        flex-direction: row;
        justify-content: flex-start;
        flex-wrap: wrap;

        .search__content {
            contain: content;
            border: 2px solid #BBBBBB;
            border-radius: 20px;
            display: flex;
            align-items: center;
            padding: 1rem;
            margin: 1rem;

            img {
                margin: 0 0.5em;
            }

            .search__text {
                p {
                    margin: 0 auto;
                }

                .mainText {
                    font-weight: bold;
                }

                .subText {
                    font-size: 11px;
                    font-weight: bold;
                }
            }


        }
    }
}