.routeplan__container {
    max-width: 1440px;
    margin: 0 auto;
    background-color: #F3F3F3;
}

.set__background {
    max-width: 1440px;
    margin: 0 auto;
    background-color: #F3F3F3;
}