@mixin dimText {
    font-size: 12px;
    font-weight: lighter;
}


.foodModule__container {
    max-width: 100%;
    margin-top: 20px;

    .food__Header {
        margin: 0 auto;
        max-width: 1280px;
        display: flex;
        justify-content: space-around;
        align-items: center;

        .device__location {
            flex-basis: 50%;

            input {
                border: none;
                font-size: larger;
                border-bottom: 1px solid rgba(128, 128, 128, 0.466);
            }
        }
    }

    .food__Banner {
        margin-top: 20px;
        display: flex;
        justify-content: center;
        background-color: #171a29;
        // background-color: black;
        height: 340px;
        align-items: center;
        gap: 80px;

        .img__container {
            box-shadow: 0px 1px 10px 0px crimson;

            img {
                width: 260px;
            }

        }
    }

    .food__Filters {
        margin: 0 auto;
        margin-top: 50px;
        padding-bottom: 10px;
        display: flex;
        justify-content: space-around;
        border-bottom: 1px solid rgba(0, 0, 0, 0.171);
        max-width: 1280px;
        position: sticky;

        .hotel__Count {
            font-size: larger;
            font-weight: bolder;
            flex-basis: 40%
        }

        .sort__Container {
            display: flex;
            // border: 1px solid red;
            justify-content: space-around;
            flex-basis: 65%;

            .sort_heading {
                background-color: white;
                border: none;

                &:focus {
                    border-bottom: 4px solid red;
                }
            }
        }

        .filter_option {
            flex-basis: 8%;
            text-align: center;
        }
    }

    .mainContents {
        margin-top: 30px;
        display: flex;
        justify-content: flex-start;
        flex-wrap: wrap;
        gap: 30px;
        max-width: 1280px;
        margin: 0 auto;

        .item {
            border: 1px solid white;
            margin: 5px;
            max-width: 13.5rem;
            text-align: start;
            font-weight: bold;
            padding: 2em;
            border-radius: 10px;
            text-decoration: none;
            color: black;

            .hote__subDes {
                font-size: 12px;
                font-weight: lighter;
                margin: 0;
                margin-bottom: 5px;
                height: 50px;
                margin-bottom: 10px;
            }

            .hotel__detail {
                display: flex;
                justify-content: space-between;
                align-items: center;
                margin-bottom: 10px;
                border-bottom: 1px solid rgba(128, 128, 128, 0.333);

                .rating__highlight {
                    flex-basis: 10%;
                    @include dimText;
                    background-color: green;
                    color: white;
                    text-align: center;
                    margin: 0 0 5px 5px;
                }

                .priceSelection {
                    p {
                        margin: 0;
                    }

                    flex-basis: 35%;
                    white-space: nowrap;
                    @include dimText;
                }
            }

            .hotel__offers {
                color: rgba(150, 75, 37, 0.82);
                @include dimText;
            }

            .imageContainer {
                overflow: hidden;
                width: 13.5rem;
                height: 13.5rem;
            }

            img {
                object-fit: fill;
                width: inherit;
                height: inherit;
            }

            .priceSelection {
                width: 100%;
                display: flex;
                flex-direction: row;
                justify-content: space-between;
                align-items: baseline;

            }

            &:hover {
                box-shadow: 0px 3px 4px 1px #b4b4b4;

                img {
                    transition: 1s;
                    transform: scale(1.5)
                }
            }
        }
    }

    .headCount {
        font-weight: bold;
        font-size: large;
    }
}