.carousel-item {
    display: inline-flex;
    align-items: center;
    // max-width: 100%;
    justify-content: center;

    img {
        width: 100%;
        height: auto;
    }
}