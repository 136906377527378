.stop__item__container {
    display: flex;
    background-color: white;
    margin-bottom: 11px;
    padding: 15px;
    justify-content: space-between;
    border-radius: 5px;
    align-items: center;
    cursor: move;
    position: relative;

    &.onDragStart {
        opacity: 0.5;
        box-shadow: 1px 1px grey;
    }

    &.onDragOver {
        opacity: 1;
        border: 2px solid rgba(128, 128, 128, 0.527)
    }

    .stop__space {
        flex-basis: 50%;
        display: flex;
        justify-content: flex-start;

        .index__highlight {
            margin-right: 10%;
        }


    }

}