.route__banner__container {
    border: 1px solid blue;
    background-image: url('../../../../public/banner/backGound_Image.png');

    .navbar {
        margin-top: 20px;
        display: flex;
        gap: 63.4%;
        justify-content: space-around;
        margin-bottom: 58px;

        .Logo {
            background: no-repeat;
        }




    }

    .route__definition__container {

        display: flex;
        flex-direction: column;
        // border: 1px solid red;
        margin: 0 auto;
        color: white;
        align-items: center;
        gap: 15px;
        margin-bottom: 62px;

        .type_of_trip {
            font-size: 10px;
            font-weight: bold;
            // border: 1px solid yellow;
            padding: 2px 15px;
            background: white;
            color: black;
            border-radius: 5px;
        }

        h1 {
            margin: 0px;
            font-size: 32px;
        }

        .trip__info {
            font-size: 12px;
            display: flex;
            align-items: center;

            p {
                margin: 0;
                padding: 1px 10px;
            }

            .typeOfTravel__seperator {
                span {
                    padding-left: 10px;
                }
            }
        }

        .edit {
            font-size: 10px;
            // border: 1px solid yellow;
            padding: 2px 15px;
            background: rgb(219, 119, 119);
            color: black;
            border-radius: 5px;
            font-weight: bold;
        }
    }


}

.plan_topic_conatiner {
    display: flex;
    justify-content: center;
    gap: 20px;
    margin: 1.56rem 0;

    a {
        font-weight: lighter;
        text-shadow: #222222;
        text-decoration: none;
        color: #222222;
        // border: 1px solid red;
    }

    a:focus {
        font-weight: bold;
        color: red;

        .item__UnderLine {
            border-bottom: 5px solid red;
            max-width: 100%;
            margin: 11px auto;
        }
    }
}