.recommend__container {
    margin-top: 58px;

    .recommend__heading {
        display: flex;
        justify-content: space-between;

        p {
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 21px;
        }

        h1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            margin: 0 0 15px 0;

        }
    }

    .typeOfTravel__filters {
        display: flex;
        flex-wrap: wrap;
        gap: 10px;

        .filter__options {
            border: 1px solid #C1C1C1;
            border-radius: 15px;
            padding: 0.25em 0.65em;
            // margin-right: 0.65em;
        }
    }

    .recommenedTrip__display_conatiner {
        margin-top: 20px;
        // border:1px solid red;
        display: flex;
        justify-content: space-around;
        align-items: flex-end;
        flex-wrap: nowrap;

        .recommend__maxDisp {
            // border:1px solid black;
            flex-basis: 55%;
            max-width: 50.34rem;

            .tripMaxContainer {
                // margin:0 0.62rem 0.62rem 0;
                max-width: 100%;
                height: 34.625rem;
                border-radius: 15px;
                position: relative;

                // border:1px solid green;
                .info__container {
                    // border:1px solid red;
                    color: white;
                    bottom: 36px;
                    position: absolute;
                    font-family: poppins;
                    left: 28.5px;

                    h2 {
                        font-size: 28px;
                        margin: 0;
                    }

                    .locationType__container {
                        margin-top: 2px;
                        display: flex;
                        flex-direction: row;

                        p {
                            font-weight: normal;
                            margin: 0;
                            padding-left: 3px;
                            font-size: 13px;
                        }

                        // position: absolute;
                        // bottom:10px;
                        // left:21px;
                    }

                    .location__info {
                        margin-top: 20px;
                        padding-left: 3px;
                        font-size: 14px;
                    }
                }

                .heart__Wishlist {
                    position: absolute;
                    right: 15px;
                    top: 13px;
                }
            }
        }

        .recommend__minDisp {
            // border:1px solid blue;
            height: 34.625rem;
            flex-basis: 47%;
            display: flex;
            gap: 10px;
            flex-wrap: wrap;

            .tripMinContainer {
                color: white;
                flex-basis: 100%;
                font-weight: bold;
                position: relative;
                border-radius: 10px;
                max-width: 17.51rem;

                // max-height:17rem;
                //    margin:0 0.62rem 0.62rem 0;
                .heart__Wishlist {
                    position: absolute;
                    right: 15px;
                    top: 13px;
                }

                .arrow__go {
                    position: absolute;
                    right: 15px;
                    bottom: 23px;
                }

                .locationType__container {
                    display: flex;
                    flex-direction: row;
                    position: absolute;
                    bottom: 10px;
                    left: 21px;

                    p {
                        font-weight: normal;
                        display: flex;
                        align-content: center;
                        padding-left: 3px;
                    }
                }

                h2 {
                    position: absolute;
                    font-size: 29px;
                    bottom: 28px;
                    left: 21px
                }

            }
        }
    }
}