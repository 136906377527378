.signIn_Out_Icon {
    display: flex;
    gap: 28px;
    align-items: center;
    background-color: none;

    .row {
        display: flex;

        .badge__button {
            position: relative;

            .badge {
                position: absolute;
                right: 0px;
                color: red;
                font-weight: bold;
                background-color: rgb(28, 35, 42);
                color: rgb(254, 254, 254); // border: 2px solid green;
                padding: 5px;
                border-radius: 100px;
                font-size: xx-small;
            }
        }

        .imgIcon {
            width: 50px;
            height: 50px;
            color: white;
        }
    }


    .signIn_Out {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        padding: 5px 10px 5px 10px;
        border-radius: 15px;
        position: relative;

        :nth-child(2) {
            padding-left: 0.2em;
        }

        .signIn_OUT_Dropdown {
            transition: opacity .2s ease-out, visibility .2s ease-out;
            border-radius: 10px;
            position: absolute;
            background-color: #fff;
            border: 1px solid #f5f5f6;
            z-index: 101;
            width: 250px;
            left: -137.5px;
            top: 50px;
            padding: 25px 20px 15px;
            box-shadow: 0 1px 10px rgb(0 0 0 / 8%);
            font-size: 14px;
            margin: 0 auto;

            :nth-child(2) {
                font-size: 9px;
                font-weight: lighter;
                margin: 10px 0px;
                white-space: nowrap;
            }

            p {
                margin: 0;
            }

            button {
                padding: 10px;
                border: 1px solid rgb(159, 158, 158);
                background-color: rgb(255, 255, 255);
                color: red;
                font-weight: bold;
            }
        }
    }

}