.radioGap {
    gap: 5PX;
    display: flex;
    ;

    .radio {
        margin: 0;
    }

    .gapfill {
        margin-left: 5px;
    }
}