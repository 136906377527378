@mixin buttonFetaure {
    background-color: rgb(252, 187, 187);
    display: flex;
    align-items: center;
    border-radius: 5px;
    gap: 10px;
    border: 1px solid red;
    height: 30px;

}

@mixin buttonIcon {
    margin: 0px 0px 0px 0px !important;
    max-height: 25px;
    max-width: 25px;
}

.overImageButton {
    @include buttonFetaure;
    top: 123px;
    left: 40px;
    position: absolute;

    .subIcon {
        @include buttonIcon
    }
}

.standAloneButton {
    @include buttonFetaure;

    margin-right: 2rem;

    .subIcon {
        @include buttonIcon
    }

}