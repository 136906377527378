.section__Container {
    position: absolute;
    top: 110%;
    border-radius: 30px;
    border: 1px solid #BBBBBB;
    box-shadow: 0px 7px 15px rgb(0 0 0 / 12%);
    background: #F8F8F8;
    z-index: 100;
    min-width: 57%;

    // max-height: 484px;
    .section {
        display: flex;
        gap: 15px;

        &.row {
            flex-direction: row;
        }

        &.column {
            flex-direction: column;
        }

        &.setBaseline {
            align-items: end;
        }

        // :nth-child(1) {
        //     padding: 30px 32px 15px 32px;
        // }

        padding: 30px 32px 0px 32px;

        &.border {
            border-bottom: 1px solid rgba(0, 0, 0, 0.09);
            padding-bottom: 15px;
        }

        .row {
            flex-direction: row;
        }

        .column {
            flex-direction: column;
        }

        .typeOfFilter {
            display: flex;

            .dropdown_label {
                margin: 0;
                font-size: 12px;
                opacity: 0.6;
            }

            .selection_label {
                margin-bottom: 16px;
            }

            .radio {
                display: flex;
                align-content: center;
                margin-right: 23px;

                label {
                    padding-left: 8px;
                    font-size: 16px;
                }

                input {
                    width: 1.3rem;
                    margin: 0;
                }
            }

            .date {
                display: flex;
                flex-direction: column;
                // width: 282px;
                // height: 52px;

                input {
                    width: 16.8rem;
                    height: 42px;
                    border: none;
                    border-bottom: 1px solid black;
                }

                label {
                    opacity: 0.6;
                    font-size: 12px;
                }
            }

            .button_arrange {
                display: flex;
                flex-wrap: wrap;
                align-content: space-between;
                gap: 11px;
            }


        }
    }


    .search__Submit {
        display: flex;
        justify-content: flex-end;
        gap: 15px;
        padding: 30px 32px 30px 32px;

        .skip__route {
            border: none;
            background-color: #F8F8F8;
            color: rgba(1, 1, 1, 0.700);


        }

        .skip__route:hover {
            background-color: black;
            color: white;
            border-radius: 10px;
        }

        .submit__button {
            border: none;
            padding: 11px 26px;
            color: white;
            background-color: #FF4B56;
            border-radius: 10px;
        }
    }


}