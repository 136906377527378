.dropdown__HC {
    display: flex;
    justify-content: space-between;
    border-bottom: 1px solid black;
    align-items: center;
    gap: 10px;
    position: relative;

    p {
        margin: 13px 0 5px 0;

    }

    img {
        height: 10px;
        width: 12px;
    }

    .show__option {
        z-index: 101;
        position: absolute;
        // top: 12px;
        // left: 187px;
        min-width: 18.25rem;
        background: white;
        border-radius: 32px;
        padding: 30px;

        .hc__conatiner>*:last-child {
            border-bottom: none;
        }

        .indv__Options {
            display: flex;
            border-bottom: 1px solid rgb(0, 0, 0, 0.2);
            justify-content: space-between;
            align-items: center;
            padding: 10px 0px;

            .option_label {
                flex-basis: 70%;

                p {
                    font-size: small;
                    opacity: 0.6;
                    margin: 0;
                }
            }

            .count_IncDec {
                display: flex;
                justify-content: space-around;
                flex-basis: 50%;
                align-items: flex-end;
                margin-bottom: 15px;

                button {
                    border-radius: 100%;
                    height: 35px;
                    width: 35px;
                    background-color: white;
                    border: 1px solid rgb(0, 0, 0, 0.5);

                    &:hover {
                        border: 1px solid black;

                        .plusminus {
                            scale: 1.6
                        }
                    }

                    &:disabled {
                        background: 1px solid rgb(0, 0, 0, 0.2);
                        border: 1px solid rgb(0, 0, 0, 0.2);

                        .plusminus {
                            opacity: 0.2;
                        }
                    }
                }
            }

        }


    }
}