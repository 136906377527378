.display___dates {
    border: 1px solid #F3CCCE;
    background-color: #FFEEEF;
    border-radius: 15px;
    font-size: 11px;
    display: flex;
    align-items: center;
    justify-content: center;

    gap: 1px;

    p {
        margin: 0;
        padding: 2px 10px 2px 14px;
    }
}