.search_Container {
    border: 1px solid #BBBBBB;
    box-shadow: 0px 7px 15px rgba(0, 0, 0, 0.12);
    border-radius: 200px;
    background: #F8F8F8;
    display: flex;
    padding: 1em 2em;
    margin: 0 2rem;
    z-index: 100;
    position: relative;

    .src_location {
        display: flex;
        align-items: center;
        border-right: 3px solid #BBBBBB;
        width: 30%;

        img:first-child {
            padding-right: 2px;
        }

        img {
            padding-right: 10px;
        }

        .src {
            width: 30%;
            width: 90%;

            input {
                font-size: 14px;
                background-color: #F8F8F8;
                border: none;
                padding: 0.5em;
                width: 90%;

                &:focus-visible {
                    outline: none
                }
            }
        }
    }

    .dest_find {
        display: flex;
        align-items: center;
        width: 70%;
        padding-left: 1em;

        .dest {
            padding-left: 0.1em;
            width: 100%;



            input {
                background-color: #F8F8F8;
                width: inherit;
                padding: 0.5em;
                border: none;

                &:focus-visible {
                    outline: none
                }
            }
        }
    }
}