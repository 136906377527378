.success__container {
    // border-bottom: 1px solid rgba(0, 0, 0, 0.763);
    max-width: 1440px;
    margin: 0 auto;
    text-align: center;

    .success__Header {
        margin: 20px auto;

        display: flex;
        justify-content: space-around;
        align-items: center;
        padding-bottom: 10px;
        box-shadow: -2px 14px 11px -5px #0000007a;

        // border-bottom: 1px solid rgba(0, 0, 0, 0.763);

        .device__location {
            flex-basis: 50%;

            input {
                border: none;
                font-size: larger;
                border-bottom: 1px solid rgba(128, 128, 128, 0.466);
            }
        }
    }

    .success__details {
        border: 1px solid black;
        max-width: 800px;
        margin: 0 auto;
        padding: 10px;

        .success__Container {
            .success__heading {
                display: flex;
                justify-content: space-between;
                border-bottom: 1px solid black;
                align-items: center;
            }

            .success__items {

                .order__details {
                    display: flex;
                    justify-content: space-between;
                    padding: 15px;
                    margin-top: 15px;
                    border-bottom: 1px solid black;
                    ;

                    img {
                        width: 200px;
                        border-radius: 15px;

                    }
                }
            }


        }
    }

    .routeContainer {
        .routeButton {
            padding: 10px 15px;
            border-radius: 5px;
            border: none;
            margin-top: 10px;
            color: #fffcfc;
            background-color: #1b7070;
            font-weight: bolder;
            font-size: large;

            &:hover {
                background-color: aqua;

            }

            &:active {
                box-shadow: rgb(0 0 0 / 20%) 0px 5px 5px -3px, rgb(0 0 0 / 14%) 0px 8px 10px 1px, rgb(0 0 0 / 12%) 0px 3px 14px 2px;
            }

        }

        .route_map_container__expaned {
            min-width: 70%;
            min-height: 70%;
            position: absolute;
            background: white;
            box-shadow: 0px -1px 20px 0px #303030;
            top: 22%;
            left: 15%;
            padding: 10px;
            contain: content;

            .expandCloseButton {
                text-align: end;
                font-size: large;
                font-weight: bolder;
                margin-bottom: 5px;

                button {
                    border: none;
                }
            }

            .mapContainer {
                padding: 5px;
            }
        }
    }

}