@import url('https://fonts.googleapis.com/css?family=Poppins');


.nav__Container {
    max-width: 100%;
    // border: 1px solid red;
    // margin: 24px 5rem 20px;
    display: flex;
    justify-content: space-between;
    position: sticky;
    z-index: 99;

    img {
        max-width: 5.1rem;
        max-height: 3.1rem;
    }

    .nav__List {
        font-family: 'Poppins';
        font-style: normal;
        font-weight: 600;
        // border: 2px solid green;
        flex-grow: 1;
        display: flex;
        align-items: flex-start;

        .nav__itemSearch {
            // border: 1px solid blue;
            margin: 0 auto;
            display: flex;
            flex-grow: 0.4;
            flex-direction: column;

            .nav__Item {
                display: flex;
                justify-content: space-around;
                margin-bottom: 1.56rem;

                .arrow {
                    max-width: 1.7rem;
                    max-height: 1.7rem;
                }
            }

            a {
                font-weight: lighter;
                text-shadow: #222222;
                text-decoration: none;
                color: #222222;
            }

            a:first-child,
            a:visited {
                font-weight: bold;

                .item__UnderLine {
                    border-bottom: 2px solid red;
                    max-width: 2em;
                    margin: 9px auto;
                }

            }

            a:focus {
                font-weight: bold;

                .item__UnderLine {
                    border-bottom: 2px solid red;
                    max-width: 2em;
                    margin: 9px auto;
                }
            }
        }

    }

    .signIn_Out {
        display: flex;
        justify-content: space-around;
        cursor: pointer;
        padding: 5px 10px 5px 10px;
        border-radius: 15px;
        position: relative;

        :nth-child(2) {
            padding-left: 0.2em;
        }

        .signIn_OUT_Dropdown {
            transition: opacity .2s ease-out, visibility .2s ease-out;
            border-radius: 10px;
            position: absolute;
            background-color: #fff;
            border: 1px solid #f5f5f6;
            z-index: 101;
            width: 250px;
            left: -137.5px;
            top: 50px;
            padding: 25px 20px 15px;
            box-shadow: 0 1px 10px rgb(0 0 0 / 8%);
            font-size: 14px;
            margin: 0 auto;

            :nth-child(2) {
                font-size: 9px;
                font-weight: lighter;
                margin: 10px 0px;
                white-space: nowrap;
            }

            p {
                margin: 0;
            }

            button {
                padding: 10px;
                border: 1px solid rgb(159, 158, 158);
                background-color: rgb(255, 255, 255);
                color: red;
                font-weight: bold;
            }
        }
    }

}