.footer__container {
  position: relative;
  background-color: rgb(237, 237, 237, 0.6);
  border: 1px solid white;
  max-width: 1440px;
  margin: 0 auto;
  margin-top: 164px;

  // opacity: 0.6;
  .footer__content {
    display: flex;
    position: relative;
    margin: 13.68% 5.31% 4.25%;

    .FT_LOGO {
      flex-basis: 10%;
      max-width: 3.81rem;
      max-height: 3.81rem;
    }

    .content {
      margin-left: 1rem;
      flex-basis: 70%;

      p {
        font-size: 14px;
      }

      .sub_points {
        list-style-type: none;
        display: flex;
        gap: 50px;
        justify-content: flex-start;
        font-size: 18px;

        li {
          padding-right: 1rem;
          font-weight: bold;
        }
      }
    }

    .socialMedia__icon {
      flex-basis: 10%;
    }
  }
}
