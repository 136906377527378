.giftcard__container {
    display: flex;
    justify-content: space-around;
    align-items: center;
    margin: 0 auto;
    max-width: 1440px;
    margin-top: 4.68rem;
    padding: 4.48rem 8.625rem;

    .carousel {
        overFlow-x: hidden;
        max-width: 51.065rem;
        flex-grow: 1;
        height: 220px;
        position: relative;

        .inner {
            white-space: nowrap;
            transition: transform 1s;
            display: inline-flex;

            .scaleClass {
                z-index: 100 !important;
                transform: scale(1.32);
                margin-top: 31px !important;
                border-radius: 36px;
                transition: 0.5s ease-in;
                box-shadow: 0px 0px 11px 3px #ffffff;
            }

            .descendClass {
                margin-top: 31px !important;
                border-radius: 36px;
            }

            img {
                border-radius: 5px;
            }

            .hide {
                transition: 0.3s ease-in;
                opacity: 0;
            }
        }
    }

    .description {

        h2 {
            margin-bottom: 0px;
        }

        p {
            margin: 0 0 15px 0;
        }

        button {
            padding: 0.625rem 1.9rem;
            background-color: #FF4B56;
            color: white;
            border: none;
            border-radius: 5px;
            font-size: 16px;
        }

        ;

    }
}