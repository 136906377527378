.main_conatiner {
    color: white;
    max-height: 30rem;
    margin-top: 61px;
    contain: content;

    .why_content {
        display: flex;
        flex-direction: column;
        margin: 2.93rem 3.81rem;

        .description {
            max-width: 21.87rem;

            .header {
                font-size: 16px;
                margin: 0;
            }

            .heading {
                font-size: 46px;
                margin: 26px 0 0 0;

            }

            .content {
                font-size: 14px;
            }
        }

        .uniqueness {
            display: flex;
            background-color: white;
            color: black;
            border-radius: 10px;
            margin-top: 32.52px;
            justify-content: space-around;

            .unique_indv {
                display: flex;
                align-items: center;

                .text_desc {
                    padding-left: 5px;
                    font-style: normal;
                    font-weight: 600;
                    font-size: 20px;
                }
            }
        }
    }
}