.route__item_custom {
    // border: 1px solid red;
    min-width: 16.68rem;
    display: flex;
    justify-content: center;
    flex-direction: column;
    align-items: center;
    padding: 16px;
    border-radius: 10px;
    background-color: white;

    .add__route__container {
        display: flex;
        justify-content: center;
        flex-direction: column;
        align-items: center;
    }

    .route_map_container__expaned__custom {
        width: 100%;
        height: 98%;
        position: absolute;
        top: 0;
        left: 0;
        background: white;
        box-shadow: 0px -1px 20px 0px #303030;
        padding: 10px 0px;
        contain: content;
        border-radius: 5px;

        .expandCloseButton__custom {
            display: flex;
            justify-content: space-between;
            align-items: center;

            h3 {
                margin: 0;
                margin-left: 56px;
            }

            button {
                background-color: white;
                border: none;
                font-size: large;
                margin-right: 10px;
            }
        }

        .custom_route_edit_container {
            display: flex;
            justify-content: space-around;
            background: #F3F3F3;
            margin-top: 10px;
            height: 92%;
            overflow: auto;

            .edit_route {
                flex-basis: 35%;
                padding-top: 4rem;
                min-width: 22.5rem;
                font-weight: bolder;


                .stops__scrolable {
                    margin-top: 10px;

                    .draggable__offsetParent {
                        margin-right: 5px;
                    }
                }

                .startLabel {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 13px;

                    p {
                        margin: 0
                    }

                    .add__stops {
                        display: flex;
                        justify-content: center;
                        cursor: pointer;
                        align-items: center;
                        font-size: 12px;

                        img {
                            padding-right: 5px;
                        }
                    }
                }
            }

            .mapContainer__custom {
                flex-basis: 50%;
                max-height: 60%;
                margin-top: 5%;
                contain: content;
                padding-top: 4rem;
                margin-bottom: 4rem;
                position: sticky;
            }
        }

        .save__cancel {
            display: flex;
            justify-content: center;
            align-items: center;

            button {
                min-width: 100px;
                border: 1px solid black;
                margin: 10px;
                padding: 10px;
                border-radius: 20px;
                background: white;

                &:hover {
                    background-color: black;
                    color: white
                }

                &:active {
                    background-color: black;
                    color: white
                }
            }
        }
    }

}