.promo__Cotainer {
    margin-top: 63px;

    .promo_Heading {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        h1 {
            font-family: 'Poppins';
            font-style: normal;
            font-weight: 600;
            font-size: 24px;
            line-height: 36px;
            margin: 0;
        }

        .arrow_pos {
            img {
                padding: 0 8px;
            }

        }
    }

    .carousel {
        margin-top: 20px;
        // border-radius:30px;
        overFlow: hidden;
        position: relative;

        // background:black;
        .inner {
            white-space: nowrap;
            transition: transform 0.5s;

        }


        .active__Dots {
            // border: 1px solid red;
            z-index: 5;
            width: 5em;
            bottom: 14px;
            right: 10px;
            padding: 1rem;
            position: absolute;

            img {
                padding: 5px
            }


        }

    }
}