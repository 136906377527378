.button-container {
    min-width: 165px;
    width: auto;
    height: 50px;
    border-radius: 10px;
    letter-spacing: 0.5px;
    line-height: 50px;
    padding: 0 35px 0 35px;
    font-size: 15px;
    background-color: rgb(236, 56, 56, 90%);
    color: white;
    text-transform: uppercase;
    font-weight: bolder;
    border: none;
    cursor: pointer;
    // display: flex;
    // flex-grow: 50%;
    // justify-content: center;

    &:hover {
        background-color: white;
        color: black;
        letter-spacing: 0;
        border: 1px solid black;
    }

    &.google-sign-in {
        background-color: #4285f4;
        color: white;

        &:hover {
            background-color: white;
            color: black;
            font-size: 15.7px;
            border: 1px solid black;
        }
    }

    &.inverted {
        background-color: white;
        color: black;
        border: 1px solid black;

        &:hover {
            background-color: black;
            color: white;
            border: none;
        }
    }

    &.googleButton {
        background-color: #4285f4;
        color: white;

        &:hover {
            background-color: #357ae8;
            border: none
        }
    }
}